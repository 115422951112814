var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _c(
            "CCard",
            { attrs: { "accent-color": "danger" } },
            [
              _c("CCardHeader", { attrs: { color: _vm.$theme.backColor } }, [
                _c(
                  "div",
                  { staticClass: "default-card-header" },
                  [
                    _c("CIcon", { attrs: { name: "cil-wifi-signal-3" } }),
                    _vm._v(" Devices ")
                  ],
                  1
                )
              ]),
              _c(
                "CCardBody",
                [
                  _c("DeviceManagementGrid", {
                    attrs: {
                      items: _vm.inventory,
                      small: false,
                      striped: true,
                      "show-toggle": false
                    },
                    on: { clickDetails: _vm.toggleDetails },
                    scopedSlots: _vm._u([
                      {
                        key: "details",
                        fn: function(item) {
                          return [
                            _c(
                              "CCard",
                              [
                                _c(
                                  "CRow",
                                  {
                                    staticClass:
                                      "pb-0 no-gutters device-details-card"
                                  },
                                  [
                                    _c(
                                      "CCol",
                                      {
                                        staticClass: "pb-0",
                                        attrs: { col: "10" }
                                      },
                                      [
                                        _c("DeviceDetails", {
                                          staticClass: "h-100 pb-0",
                                          attrs: { info: _vm.getInfo(item) }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "CCol",
                                      { attrs: { col: "2" } },
                                      [
                                        _c(
                                          "CCard",
                                          {
                                            staticClass:
                                              "pb-0 device-details-card"
                                          },
                                          [
                                            _c(
                                              "CRow",
                                              [
                                                _c(
                                                  "CCol",
                                                  { staticClass: "p-2 m-4" },
                                                  [
                                                    _c(
                                                      "CButtonGroup",
                                                      {
                                                        staticClass: "btn-block"
                                                      },
                                                      [
                                                        _c(
                                                          "CButton",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "c-tooltip",
                                                                rawName:
                                                                  "v-c-tooltip",
                                                                value:
                                                                  "Manage Device",
                                                                expression:
                                                                  "'Manage Device'"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "m-0 p-1",
                                                            attrs: {
                                                              color: "info",
                                                              square: "",
                                                              variant:
                                                                "outline",
                                                              size: "sm"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.handleManageClick(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("div", {}, [
                                                              _vm._v("Manage")
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "CButton",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "c-tooltip",
                                                                rawName:
                                                                  "v-c-tooltip",
                                                                value:
                                                                  "View Device",
                                                                expression:
                                                                  "'View Device'"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "m-0 p-1",
                                                            attrs: {
                                                              color: "info",
                                                              square: "",
                                                              variant:
                                                                "outline",
                                                              size: "sm"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.handleViewClick(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("div", {}, [
                                                              _vm._v("View")
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }